<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Create new group
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <table class="table table-google">
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Group Key</td>
              <td>
                <b-form-input
                  v-model="group.group_key"
                  placeholder="Group Key"
                  size="sm"
                  autocomplete="off"
                  trim
                  autofocus
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td>Group Name</td>
              <td>
                <b-form-input
                  v-model="group.group_name"
                  placeholder="Group Name"
                  size="sm"
                  autocomplete="off"
                  trim
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Create
            </b-btn>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import { hasWhitespace } from '@/helpers';

export default {
  name: 'SelectCreator',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (this.group.group_key.length === 0) return false;
      if (hasWhitespace(this.group.group_key)) return false;
      if (this.group.group_name.length === 0) return false;
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      group: {
        group_key: '',
        group_name: '',
      },
    };
  },
  methods: {
    createGroup() {
      this.loadingCount++;
      this.$http
        .post('/select/groups')
        .send({ data: this.group })
        .then((res) => {
          this.$emit('created', res.body.group);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to create new group: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.$store.dispatch('fetchDropdowns');
        });
    },
    onSave() {
      this.createGroup();
    },
    onShow() {},
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
